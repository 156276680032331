 <template>
  <a-row
    align="space-around"
    id="members"
    class="mb members white-bg"
    justify="center"
    type="flex"
  >
    <a-col
      v-for="membre in membres"
      :key="membre.id"
      :md="4"
      style="margin-bottom: 20px"
    >
      <div> 
        <div class="img" v-if="membre.photo">
          <img
            :src="membre.photo_url"
            :alt="membre.full_name"
            class="img"
          />
        </div>
          
        <div>
          <h3 class="title">
            {{ membre.full_name }}
          </h3>
          <p class="grade">{{ membre.grade }}</p>
        </div>
      </div>
    </a-col>
  </a-row>
</template>
<script>
export default {
  name: "Members",
  props: { membres: { type: Array } },
};
</script>
<style>
.members {
  padding-top: 2%;
}
.title {
  margin-top: 10px;
}
.img {
  max-width: 226px;
  display: block;
  width: auto;
}
.grade {
  font-size: larger;
}
.txt-center {
  text-align: center;
}
h1,
h2,
h3,
h4,
h5,
h6,
.my-blue {
  font-family: Sora, ubuntu, monospace;
  font-weight: 600;
  color: rgb(31 54 92);
}
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      staticClass: "mb members white-bg",
      attrs: {
        align: "space-around",
        id: "members",
        justify: "center",
        type: "flex"
      }
    },
    _vm._l(_vm.membres, function(membre) {
      return _c(
        "a-col",
        {
          key: membre.id,
          staticStyle: { "margin-bottom": "20px" },
          attrs: { md: 4 }
        },
        [
          _c("div", [
            membre.photo
              ? _c("div", { staticClass: "img" }, [
                  _c("img", {
                    staticClass: "img",
                    attrs: { src: membre.photo_url, alt: membre.full_name }
                  })
                ])
              : _vm._e(),
            _c("div", [
              _c("h3", { staticClass: "title" }, [
                _vm._v("\n          " + _vm._s(membre.full_name) + "\n        ")
              ]),
              _c("p", { staticClass: "grade" }, [_vm._v(_vm._s(membre.grade))])
            ])
          ])
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }